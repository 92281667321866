

























































import { Component, Prop, Vue } from 'vue-property-decorator';

import IconMessage from '@/shared/resources/components/IconMessage.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridSpacer from '@/shared/resources/components/grid/GridSpacer.vue';
import InputField from '@/shared/resources/components/forms/fields/InputField.vue';

import AuthRegisterDataInterface from '@/app/modules/auth/interfaces/AuthRegisterDataInterface';
import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel';
import CheckboxField from '@/shared/resources/components/forms/fields/CheckboxField.vue';
import { Validations } from 'vuelidate-property-decorators';
import { authValidation } from '@/app/modules/auth/config/authValidation';

@Component({
  components: {
    CheckboxField,
    InputField,
    GridSpacer,
    GridRow,
    Button,
    Divider,
    IconMessage,
  },
})
export default class AuthRegisterContractorFound extends Vue {
  /**
   * Validations
   */
  @Validations()
  private get registerRules() {
    return authValidation();
  }

  /**
   * Props
   */
  @Prop() private data!: AuthRegisterDataInterface;
  @Prop() private contractor!: ContractorCoreModel;
  @Prop() private value!: string;
  @Prop() private loading!: boolean;

  /**
   * Methods
   */
  private submit() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.$emit('submit');
    }
  }

  /**
   * Handlers
   */
  private onBackButtonClick() {
    this.$emit('click:back');
  }

  private onFormSubmit(e: Event) {
    e.preventDefault();
    this.submit();
  }

  private onRegulationsLinkClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.$emit('click:regulations');
  }
}
