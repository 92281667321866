import { required, requiredIf, email, sameAs, minLength, url } from 'vuelidate/lib/validators';
import { password, trueValue, nipRaw, zipCode } from '@/shared/lib/support/validation/ValidationRules';
import { AuthApplicationInterface } from '@/app/modules/auth/interfaces/AuthApplicationInterface';

/**
 * Check nip validation
 */
export const authCheckNipValidation = () => {
  return {
    data: {
      nip: { required },
    },
  };
};

/**
 * Register validation
 */
export const authValidation = () => {
  return {
    data: {
      email: { required, email },
      regulations: { trueValue },
    },
  };
};

/**
 * Password validation
 */
export const authPasswordValidation = () => {
  return {
    data: {
      password: {
        required,
        password,
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  };
};

/**
 * Password Reset validation
 */
export const authPasswordResetValidation = () => {
  return {
    email: { required, email },
  };
};

/**
 * Application validation
 */
export const authApplicationValidation = () => {
  return {
    data: {
      nip: { required, nipRaw },

      name: { required },
      address: { required },
      zipCode: { required, zipCode },
      place: { required },

      email: { required, email },
      emailConfirm: { required, email, sameAsEmail: sameAs('email') },
      phone: { required },

      industry: { required },
      industryComment: {
        required: requiredIf((application: AuthApplicationInterface) => {
          return application.industry ? !!application.industry.commentRequired : false;
        }),
      },
      www: { required, url },
    },
  };
};

