/**
 * Validate NIP
 * @param value
 */
export const nip = (value: string | null) => {
  if (!value) {
    return false;
  }

  return nipRegex.test(value);
};

/**
 * Validate raw NIP
 * @param value
 */
export const nipRaw = (value: string | null) => {
  if (!value) {
    return false;
  }

  return nipRawRegex.test(value);
};

/**
 * Validate zip code
 * @param value
 */
export const zipCode = (value: string | null) => {
  if (!value) {
    return false;
  }

  return zipCodeRegex.test(value);
};

/**
 * Validate value against true value
 * @param value
 */
export const trueValue = (value: boolean | null) => {
  return value === true;
};

/**
 * Validate password
 * @param value
 */
export const password = (value: string | null) => {
  if (!value) {
    return false;
  }

  return passwordRegex.test(value);
};

/**
 * Define regexes
 */
const nipRegex = /^\d{3}-\d{3}-\d{2}-\d{2}$/;
const nipRawRegex = /^[0-9]*$/;
const zipCodeRegex = /^\d{2}-\d{3}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.])(?=.{8,})/; // TODO add more special chars
